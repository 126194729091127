import React from 'react'
import { Container, Apply } from './styles'

const AboutButton = ({ mobile }) => (
  <Container
    target="_blank"
    mobile={mobile}
    to="https://forms.gle/CmYZALsMri3YRvGd8"
  >
    <Apply>APPLY FOR 2024 SEASON</Apply>
  </Container>
)

export default AboutButton
