import React from 'react'
import {
  Container,
  InstaImage,
  ImageRow,
  InstagramWrapper,
  AboutWrapper,
  Header,
  Body,
  PhotosLink,
  ImageWrapper,
  ImageOverlay
} from './styles'

import PICTURES from './constants'

const About = () => {
  const row1 = PICTURES.row1
  const row2 = PICTURES.row2
  const row3 = PICTURES.row3
  return (
    <Container id="about">
      <InstagramWrapper>
        <ImageRow>
          {row1.map(
            (img) => (
              <ImageWrapper key={img.alt}>
                <InstaImage src={img.src} alt={img.alt}></InstaImage>
              </ImageWrapper>
            )
          )}
        </ImageRow>
        <ImageRow>
          {row2.map(
            (img) => (
              <ImageWrapper key={img.alt}>
                <InstaImage src={img.src} alt={img.alt}></InstaImage>
              </ImageWrapper>
            )
          )}
        </ImageRow>
        <ImageRow>
          {row3.map(
            (img) => (
              <ImageWrapper key={img.alt}>
                <InstaImage src={img.src} alt={img.alt}></InstaImage>
              </ImageWrapper>
            )
          )}
        </ImageRow>
      </InstagramWrapper>
      <AboutWrapper>
        <Header accent>YOU BRING THE IDEAS.</Header>
        <Header>WE'LL TAKE CARE OF THE REST.</Header>
        <Body>
          The annual MakeHarvard engineering makeathon will return this February
          for its seventh year. Competing teams at our 2024 event will have the
          chance to win awesome prizes and network with engineers all around the
          world.
        </Body>
        <PhotosLink
          target="_blank"
          href="https://www.instagram.com/makeharvard/"
        >
          SEE MORE PHOTOS
        </PhotosLink>
      </AboutWrapper>
    </Container>
  )
}

export default About
