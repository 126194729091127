'use-strict'
import React, { useState, useEffect } from 'react'
import Banner from './styles'

const MLHBanner = () => {
  const [show, setShow] = useState(false)
  const checkScroll = () => {
    if (window.scrollY > window.innerHeight) {
      setShow(true)
    }
  }

  useEffect(() => {
    const listener = window.addEventListener('scroll', checkScroll)
  }, [])

  return (
    <a
      id="mlh-trust-badge"
      style={{
        display: 'block',
        maxWidth: '100px',
        minWidth: '60px',
        position: 'fixed',
        right: '50px',
        top: '0',
        width: '10%',
        zIndex: '10000'
      }}
      href="https://mlh.io/seasons/2022/events?utm_source=na-hackathon&utm_medium=TrustBadge&utm_campaign=2022-season&utm_content=gray"
      target="_blank"
    >
      <Banner
        src="https://s3.amazonaws.com/logged-assets/trust-badge/2022/mlh-trust-badge-2022-gray.svg"
        alt="Major League Hacking 2022 Hackathon Season"
      />
    </a>
  )
}

export default MLHBanner
