import React from 'react'
import { CopyrightWrapper, CopyrightText, CopyrightLink } from './styles'

const Copyright = () => (
  <CopyrightWrapper>
    <CopyrightText>
      As an MLH member event, we use the{' '}
      <CopyrightLink href="https://static.mlh.io/docs/mlh-code-of-conduct.pdf">
        MLH Code of Conduct
      </CopyrightLink>
    </CopyrightText>
    <CopyrightText>
      © 2023 Copyright <CopyrightLink>MakeHarvardCollege</CopyrightLink>
    </CopyrightText>
  </CopyrightWrapper>
)

export default Copyright
